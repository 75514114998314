import React, { useEffect, useState } from 'react';
import { revealImage } from '../../lib/util';
import { SFDoc } from '../../shared-state/CompanyDocuments/companyDocuments';
import { getImgSrcFromExt, getImgSrcPlaceholder, renderSrc } from '../../lib/files';
import { viewFilesFullScreen } from '../../managers/FileViewerManager/FileViewerManager';
import { getCachedImgSrcs } from '../../shared-state/FileSyncSystem/filesToLoad';
import './SeaFileImage.css';

interface SeaFileImageProps {
    files?: string[], // array of "<state><id>.<ext>"
    sfdoc?: SFDoc,
    size?: 'tiny' | 'medium' | 'full',
    mode?: 'vessel' | 'customForms',
    showOthers?: boolean
}

const SeaFileImage: React.FC<SeaFileImageProps> = ({ files, sfdoc, size = 'tiny', mode, showOthers }) => {
    const [srcs, setSrcs] = useState<string[]>();

    useEffect(() => {
        if (files && files.length > 0) {
            let isActive = true;
            const fileType = (size === 'tiny' && mode !== 'customForms') ? 'T' : 'F';
            getCachedImgSrcs(files, fileType, (srcs) => {
                if (!isActive) return;

                const _srcs: string[] = [];
                const promises: Promise<void>[] = [];
                if (size === 'tiny' && showOthers) {
                    // Multiple tinies
                    for (let i = 0; i < srcs.length; i++) {
                        promises.push(
                            renderSrc(
                                files[i],
                                srcs[i],
                                (mode === 'customForms') ? 'medium' : size
                            ).then((_src: string) => {
                                _srcs[i] = _src;
                            })
                        );
                    }
                } else {
                    // Main image
                    promises.push(
                        renderSrc(
                            files[0],
                            srcs[0],
                            size
                        ).then((_src: string) => {
                            _srcs[0] = _src;
                        })
                    );

                    // Other images under main image?
                    if (size === 'medium' && files.length > 1 && showOthers && !(mode && mode === 'vessel')) {
                        for (let i = 1; i < srcs.length; i++) {
                            promises.push(
                                renderSrc(
                                    files[i],
                                    srcs[i],
                                    'tiny'
                                ).then((_src: string) => {
                                    _srcs[i] = _src;
                                })
                            );
                        }
                    }
                }

                Promise.all(promises).then(() => {
                    if (!isActive) return;
                    setSrcs(_srcs);
                });

            });

            return () => { isActive = false; };
        } else {
            setSrcs([getImgSrcPlaceholder(size)]);
        }
    }, [files]); // eslint-disable-line react-hooks/exhaustive-deps

    // sfdoc
    if (
        (files === undefined || files.length === 0) &&
        sfdoc &&
        Object.keys(sfdoc).length > 0
    ) {
        return <img
            alt="sfdoc"
            className={`sea-file-image fade ${size} ${mode ? mode : ''} file`}
            src={getImgSrcFromExt('sfdoc', size)}
            onLoad={revealImage}
        />;
    }

    // Loading srcs
    if (srcs === undefined) {
        return <></>;
    }

    // No files = return empty (unless size=tiny)
    if ((files === undefined || files.length === 0) && size !== 'tiny') {
        return <></>;
    }

    const onClick = (e: React.MouseEvent, index = 0) => {
        if (files === undefined || files.length === 0 || (mode && mode === 'vessel')) {
            return; // don't handle it
        }
        e.stopPropagation();
        e.preventDefault();
        viewFilesFullScreen(files, index);
    };

    // Multiple tinies
    if (size === 'tiny' && showOthers && files && files.length > 1) {
        return (<>
            {files?.map((file, index) => {
                // If we decide to implement thumbnail pdf views, uncomment this
                // if (file.endsWith('.pdf')) {
                //     return <SeaPDFViewer key={file} file={file} size="tiny2" maxPages={1} onClick={(e) => onClick(e, index)} />
                // }
                return <img
                    alt={file}
                    key={file}
                    className={`sea-file-image fade tiny other`}
                    style={{margin: '0px 6px 6px 0px'}}
                    src={srcs[index]}
                    onClick={(e) => {onClick(e, index)}}
                    onLoad={revealImage}
                />
            })}
        </>);
    }

    return (<>
    {/* If we decide to implement thumbnail pdf views, uncomment this */}
        {/* {files?.[0] && files[0].endsWith('.pdf') ? 
            <SeaPDFViewer file={files[0]} size={size} maxPages={1} onClick={(e) => onClick(e, 0)} />
            :
        } */}
            <img
                alt={files?.[0] ? files[0] : 'file'}
                className={`sea-file-image fade ${size} ${mode ? mode : ''} ${srcs[0].startsWith('/assets/file') ? 'file' : 'image'}`}
                src={srcs[0]}
                onClick={(e) => {onClick(e, 0)}}
                onLoad={revealImage}
            />
        {size === 'medium' && files && files.length > 0 && !(mode && mode === 'vessel') &&
            <div style={{textAlign: 'right', padding: '4px 0px 20px 4px'}}>
                {showOthers && files.length > 1 && files.map((file, index) => {
                    if (index > 0) {
                        // If we decide to implement thumbnail pdf views, uncomment this
                        // if (file.endsWith('.pdf')) {
                        //     return <SeaPDFViewer key={file} file={file} size="tiny2" maxPages={1} onClick={(e) => onClick(e, index)} />
                        // }
                        return <img
                            alt={file}
                            key={file}
                            className={`sea-file-image fade tiny other`}
                            src={srcs[index]}
                            onClick={(e) => {onClick(e, index)}}
                            onLoad={revealImage}
                        />
                    }
                    return undefined;
                })}
                {!showOthers && files.length > 1 && `(${files.length} images)`}
            </div>
        }
    </>);

};

export default SeaFileImage;
