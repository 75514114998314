import React, { useState, useEffect, useMemo } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { usePageLimiter } from '../../../../hooks/usePageLimiter';
import { canAccessAllVessels, canAccessAnyVessels, canComplete, canEdit, permissionLevels } from '../../../../shared-state/Core/userPermissions';
import { extractSearchTerms, toFloat } from '../../../../lib/util';
import { formatDatetime, formatDate, DateTimeAcceptable } from '../../../../lib/datesAndTime';
import { renderCategoryName } from '../../../../lib/categories';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderCrewList, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { renderVesselName, renderVesselsList } from '../../../../shared-state/Core/vessels';
import { sharedState } from '../../../../shared-state/shared-state';
import { doc, serverTimestamp } from 'firebase/firestore';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { confirmAction } from '../../../../managers/ConfirmDialogManager/ConfirmDialogManager';
import { showToast } from '../../../../managers/ToastManager/ToastManager';
import { makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { CustomFormCompleted, useCustomFormsCompleted } from '../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { CustomFormVersion } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import { SeaLinks } from '../../../../components/SeaLinks/SeaLinks';
import { useLinks } from '../../../../shared-state/Core/itemLinks';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaIcon from '../../../../components/SeaIcon/SeaIcon';
import CompleteCustomForm from '../CompleteCustomForm/CompleteCustomForm';
import ViewCompletedCustomForm from '../ViewCompletedCustomForm/ViewCompletedCustomForm';
import SeaGrid from '../../../../components/SeaGrid/SeaGrid';
import SeaGridCell from '../../../../components/SeaGridCell/SeaGridCell';
import RequirePermissions from '../../../../components/RequirePermissions/RequirePermissions';
import SeaLinkButton from '../../../../components/SeaLinkButton/SeaLinkButton';
import SeaNoData from '../../../../components/SeaNoData/SeaNoData';
import SeaSearchbar from '../../../../components/SeaSearchbar/SeaSearchbar';
import './ViewCustomForm.css'

const formatElementValue = (value: DateTimeAcceptable, id: string) => {
    switch (id) {
        case 'date':
            return formatDate(value);
        case 'datetime':
            return formatDatetime(value);
        case 'checkbox':
            return value ? 'Yes' : 'No'
    }
    return ''+value;
};


interface ViewCustomFormProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    selectedCustomForm?: CustomForm,
    setShowEditCustomFormModal?: (show: boolean) => void,
    viewOnly?: boolean
}

const ViewCustomForm: React.FC<ViewCustomFormProps> = ({showModal, setShowModal, selectedCustomForm, level = 1, setShowEditCustomFormModal, viewOnly}) => {
    const userId = sharedState.userId.use(showModal);
    const superAdmin = sharedState.superAdmin.use(showModal);
    const customFormCategories = sharedState.customFormCategories.use(showModal);
    const customFormVersions = sharedState.customFormVersions.use(showModal);
    const completedCustomFormsBeforeSearch = useCustomFormsCompleted(selectedCustomForm);
    // const links = useLinks(selectedCustomForm?.id);
    const { limitTriggerElement, mapArrayWithLimit, resetPageLimit } = usePageLimiter();
    const [showCompleteCustomForm, setShowCompleteCustomForm] = useState(false);
    const [showViewCompletedCustomForm, setShowViewCompletedCustomForm] = useState(false);
    const [selectedCompletedForm, setSelectedCompletedForm] = useState<CustomFormCompleted>();
    const [completedCustomForms, setCompletedCustomForms] = useState<CustomFormCompleted[]>();
    const [latestVersion, setLatestVersion] = useState<CustomFormVersion>();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        resetPageLimit();
    }, [showModal, resetPageLimit]);

    useEffect(() => {
        setLatestVersion(undefined);
        if (
            showModal &&
            customFormVersions &&
            selectedCustomForm &&
            selectedCustomForm.latestVersion &&
            customFormVersions.byFormIdAndVersion[selectedCustomForm.id] &&
            customFormVersions.byFormIdAndVersion[selectedCustomForm.id][selectedCustomForm.latestVersion]
        ) {
            setLatestVersion(customFormVersions.byFormIdAndVersion[selectedCustomForm.id][selectedCustomForm.latestVersion]);
        }
    }, [showModal, customFormVersions, selectedCustomForm]);

    useEffect(() => {
        let isMounted = true;
        setCompletedCustomForms(undefined);
        if (completedCustomFormsBeforeSearch?.all) {
            if (searchText && searchText.trim().length > 0) {

                const terms = extractSearchTerms(searchText, true);
                const _completedForms = [] as CustomFormCompleted[];
                for (let i = 0; i < completedCustomFormsBeforeSearch.all.length; i++) {
                    if (!isMounted) break;
                    // All terms need to match at least one piece of data
                    const completedForm = completedCustomFormsBeforeSearch.all[i];
                    const version = selectedCustomForm?.id ? customFormVersions?.byFormIdAndVersion[selectedCustomForm?.id][completedForm.version] : undefined;
                    let isMatch = true;

                    for (let j = 0; j < terms.length; j++) {
                        const term = terms[j];

                        let found = false;
                        const keys = version?.form ? Object.keys(version?.form) : [];

                        keysLoop:
                        for (let k = 0; k < keys.length; k++) {
                            const key = keys[k];
                            const element = version?.form[key];
                            //console.log(`keys[${k}]=${key}`, element);
                            if (completedForm.data[key]) {
                                //console.log('data', completedForm.data[key]);
                                if (
                                    element.id === 'input' ||
                                    element.id === 'textarea' ||
                                    element.id === 'dropdown'
                                ) {
                                    if (completedForm.data[key].toLowerCase().indexOf(term) !== -1) {
                                        found = true;
                                        break;
                                    }
                                } else if (
                                    element.id === 'checkbox' ||
                                    element.id === 'yesno'
                                ) {
                                    if (completedForm.data[key] && element.label.toLowerCase().indexOf(term) !== -1) {
                                        found = true;
                                        break;
                                    }
                                } else if (element.id === 'checks') {
                                    for (let m = 0; m < element.options.length; m++) {
                                        if (
                                            completedForm.data[key][m] === 'pass' &&
                                            element.options[m].toLowerCase().indexOf(term) !== -1
                                        ) {
                                            found = true;
                                            break keysLoop;
                                        }
                                    }
                                }
                            }
                        }

                        if (!found && selectedCustomForm?.forCrew && completedForm.personnelIds) {
                            // Check personnel names
                            for (let k = 0; k < completedForm.personnelIds.length; k++) {
                                if (renderFullNameForUserId(completedForm.personnelIds[k]).toLowerCase().indexOf(term) !== -1) {
                                    found = true;
                                    break;
                                }
                            }
                        }

                        if (!found && selectedCustomForm?.forVesselIds && selectedCustomForm?.forVesselIds?.length > 0 && completedForm.vesselIds) {
                            // Check vessel names
                            for (let k = 0; k < completedForm.vesselIds.length; k++) {
                                const vesselId = completedForm.vesselIds[k];
                                if (
                                    renderVesselName(vesselId).toLowerCase().indexOf(term) !== -1
                                ) {
                                    found = true;
                                    break;
                                }
                            }
                        }

                        if (!found) {
                            isMatch = false;
                            break;
                        }
                    }

                    if (isMatch) {
                        _completedForms.push(completedForm);
                    }
                }

                if (isMounted) {
                    setCompletedCustomForms(_completedForms);
                }
            } else {
                setCompletedCustomForms(completedCustomFormsBeforeSearch.all);
            }
        }
        return () => { isMounted = false; }
    }, [searchText, completedCustomFormsBeforeSearch, customFormVersions, selectedCustomForm]);

    // keep selectedCompletedForm fresh
    useEffect(() => {
        if (showViewCompletedCustomForm && selectedCompletedForm && completedCustomFormsBeforeSearch?.byId[selectedCompletedForm.id]) {
            setSelectedCompletedForm(completedCustomFormsBeforeSearch.byId[selectedCompletedForm.id]);
        };
    }, [completedCustomFormsBeforeSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    const onEditItem = () => {
        //todo('Edit Item');
        //setShowEditItemModal(true);
        setShowEditCustomFormModal?.(true);
    };
    const onDeleteCustomForm = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        confirmAction('Are you sure you want to delete this form?', 'Yes, delete form').then(() => {
            if (!selectedCustomForm) return;
            // Will need to also delete all the versions
            const batch = splittableBatch(firestore, 20 - 0);
            const batchTrace = makeBatchTrace(batch, 'customForms', 'delete', selectedCustomForm?.id);

            batch.set(
                doc(firestore, 'customForms', selectedCustomForm.id),
                {
                    state: 'deleted',
                    deletedBy: userId,
                    whenDeleted: batchTrace.whenAction,
                    touched: serverTimestamp()
                },
                { merge: true }
            );

            // Delete all versions of this customForm
            if (customFormVersions?.byFormIdAndVersion[selectedCustomForm.id]) {
                const versions = Object.getOwnPropertyNames(customFormVersions.byFormIdAndVersion[selectedCustomForm.id]);
                versions.forEach((version) => {
                    const _version = toFloat(version);
                    const formVersion = customFormVersions.byFormIdAndVersion[selectedCustomForm.id][_version];
                    batch.set(
                        doc(firestore, 'customFormVersions', formVersion.id),
                        {
                            state: 'deleted',
                            deletedBy: userId,
                            whenDeleted: batchTrace.whenAction,
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                });
            }

            // Delete all completed customForms (so they don't show up elsewhere)
            // But, since we haven't loaded customFormsCompleted, we will rely on a function...

            logAction(
                batch,
                'Delete',
                'customForms',
                selectedCustomForm.id,
                selectedCustomForm.title
            );

            batchTrace.data = {selectedCustomForm};
            batchTrace.save(`Delete custom form ${selectedCustomForm.id}`);

            onCollectionUpdated(batch, 'customForms');
            onCollectionUpdated(batch, 'customFormVersions');

            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
            showToast(`Form/checklist has been deleted`);
        }).catch(() => {});
    };

    const onCompleteForm = () => {
        setSelectedCompletedForm(undefined);
        setShowCompleteCustomForm(true);
    };

    const onViewCompletedCustomForm = (completedCustomForm: CustomFormCompleted) => {
        if (viewOnly) return;
        console.log(`Viewing customFormsCompleted ${completedCustomForm?.id}`);
        setSelectedCompletedForm(completedCustomForm);
        setShowViewCompletedCustomForm(true);
    }

    const elementToShow = useMemo(() => {
        if (
            latestVersion?.historyElementN &&
            latestVersion.form &&
            latestVersion.form[`e${latestVersion.historyElementN}`]
        ) {
            return latestVersion.form[`e${latestVersion.historyElementN}`];
        }
        return undefined;
    }, [latestVersion]);

    const canEditCustomForm = useMemo(() => {
        return (
            (
                !viewOnly &&
                canEdit('customForms') &&
                selectedCustomForm?.forVesselIds && (
                    canAccessAllVessels(selectedCustomForm.forVesselIds) ||
                    selectedCustomForm.forVesselIds[0] === 'none'
                )
            ) || superAdmin
        );
    }, [viewOnly, selectedCustomForm?.forVesselIds, superAdmin]);

    const canCompleteCustomForm = useMemo(() => {
        return (
            (
                !viewOnly &&
                canComplete('customForms') &&
                selectedCustomForm?.forVesselIds && (
                    canAccessAnyVessels(selectedCustomForm.forVesselIds) ||
                    selectedCustomForm.forVesselIds[0] === 'none'
                )
            ) || superAdmin
        );
    }, [viewOnly, selectedCustomForm?.forVesselIds, superAdmin]);

    const linkedTo = useMemo(() => {
        if (selectedCustomForm?.forVesselIds && selectedCustomForm.forVesselIds[0] !== 'none') {
            if (selectedCustomForm?.forCrew) {
                return 'Vessels & Personnel';
            }
            return 'Vessels';
        } else if (selectedCustomForm?.forCrew) {
            return 'Personnel';
        }
        return '-';
    }, [selectedCustomForm]);

    const forVessels = useMemo(() => {
        return (selectedCustomForm?.forVesselIds && selectedCustomForm.forVesselIds[0] !== 'none');
    }, [selectedCustomForm]);

    const forCrew = useMemo(() => {
        return (selectedCustomForm?.forCrew);
    }, [selectedCustomForm]);

    return (
        <SeaModal
            title={selectedCustomForm?.title}
            showModal={showModal}
            setShowModal={setShowModal}
            level={level}
            size="semi-wide"
            viewOnly={viewOnly}
        >
            <SeaGrid>
                <SeaGridCell label="Last Updated" w="50">
                    {formatDatetime(selectedCustomForm?.latestVersion)}
                </SeaGridCell>
                <SeaGridCell label="Category" w="50">
                    {renderCategoryName(selectedCustomForm?.categoryId, customFormCategories)}
                </SeaGridCell>
                <SeaGridCell label="Linked To" w="100">
                    {linkedTo}
                </SeaGridCell>
                {selectedCustomForm?.isTemplate &&
                    <SeaGridCell label="Template" w="50">
                        This form is marked as a template!
                    </SeaGridCell>
                }
                {/* TODO: Add links */}
                {/* <SeaLinks links={links} ids={selectedCustomForm?.id ? [selectedCustomForm?.id] : []} level={level+1} viewOnly={viewOnly} /> */}
            </SeaGrid>

            <div className="view-modal-buttons" style={{marginTop: '40px'}}>
                {canEditCustomForm &&
                    <>
                        <SeaButton
                            zone="white"
                            type="submit"
                            onClick={(e) => onEditItem()}
                        >
                            <SeaIcon icon="edit" slot="start" />
                            Edit Form
                        </SeaButton>
                        <RequirePermissions
                            role="customForms"
                            level={permissionLevels.FULL}
                        >
                            <div className="spacer-wide"></div>
                            <SeaLinkButton
                                mode="standard-link"
                                onClick={(e) => onDeleteCustomForm(e)}
                            >
                                Delete Form
                            </SeaLinkButton>
                        </RequirePermissions>
                    </>
                }
            </div>
            <div className="end-info-line"></div>
            
            <div className="columns wrap">
                <h2>History</h2>
                <div className="right">
                    {canCompleteCustomForm &&
                        <SeaButton zone="white" onClick={(e) => onCompleteForm()}><SeaIcon icon="tick" slot="start" />Complete Form</SeaButton>
                    }
                </div>
            </div>

            <div>
                <SeaSearchbar
                    value={searchText}
                    setValue={setSearchText}
                />
            </div>

            <SeaNoData
                dataName="form/checklist"
                isHistory={true}
                isLoading={!completedCustomForms}
                hasNoData={completedCustomForms && completedCustomForms.length === 0}
            />

            <div className={`custom-forms ${(completedCustomForms && completedCustomForms?.length > 0) ? 'reveal' : 'conceal'} ${elementToShow ? 'has-custom-value' : ''}`}>
                <div className="sea-row-history headings-history">
                    <div>Date</div>
                    <div>Completed By</div>
                    <div>
                        {elementToShow && (elementToShow.label ? elementToShow.label : '')}
                    </div>
                    <div>Version</div>
                    {forVessels &&
                        <div>
                            {(selectedCustomForm?.vesselsElement?.allowMultiple)
                                ?
                                'Vessels / Facilities'
                                :
                                'Vessel / Facility'
                            }
                        </div>
                    }
                    {forCrew &&
                        <div>Personnel</div>
                    }
                </div>
                {mapArrayWithLimit(completedCustomForms, (item: CustomFormCompleted) => {
                    return (
                        <div key={item.id} className={`sea-card sea-row-history ${viewOnly ? '' : 'clickable'} ${item.isDraft ? 'draft' : ''}`} onClick={(e) => {onViewCompletedCustomForm(item)}}>
                            <div>{formatDate(item.whenAdded)}</div>
                            <div className="truncate">
                                {renderFullNameForUserId(item.addedBy)}
                                {item.isDraft &&
                                    <>
                                        &nbsp;(DRAFT)
                                    </>
                                }
                            </div>
                            <div
                                className="truncate-3"
                                data-label={elementToShow?.label ? elementToShow.label : ''}
                            >
                                {elementToShow &&
                                    item?.data &&
                                    item.data[`e${latestVersion?.historyElementN}`] &&
                                    formatElementValue(item.data[`e${latestVersion?.historyElementN}`], elementToShow.id)
                                }
                            </div>
                            <div className="truncate">{formatDate(item.version)}</div>
                            {forVessels &&
                                <div className="truncate">
                                    {renderVesselsList(item.vesselIds)}
                                </div>
                            }
                            {forCrew &&
                                <div className="truncate">
                                    {renderCrewList(item.personnelIds || [])}
                                </div>
                            }
                        </div>
                    )
                })}
                { limitTriggerElement}
            </div>

            {showModal &&
                <>
                    <ViewCompletedCustomForm
                        showModal={showViewCompletedCustomForm}
                        setShowModal={setShowViewCompletedCustomForm}
                        setShowCompleteCustomForm={setShowCompleteCustomForm}
                        customForm={selectedCustomForm}
                        selectedCompletedCustomForm={selectedCompletedForm}
                        level={2}
                    />
                    <CompleteCustomForm
                        showModal={showCompleteCustomForm}
                        setShowModal={setShowCompleteCustomForm}
                        level={selectedCompletedForm ? 3 : 2}
                        customForm={selectedCustomForm}
                        itemToUpdate={selectedCompletedForm}
                        attachTo={null}
                    />
                </>
            }

        </SeaModal>
    );
};

export default ViewCustomForm;
