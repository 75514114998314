import React, { useMemo, useState } from 'react';
import { sharedState } from '../../../../shared-state/shared-state';
import { debugCounts, renderDebugging } from '../../../../shared-state/Core/debugging';
import SeaTabsGroup from '../../../../components/SeaTabsGroup/SeaTabsGroup';
import SeaTab from '../../../../components/SeaTab/SeaTab';
import SeaButton from '../../../../components/SeaButton/SeaButton';

const DebuggingTab: React.FC = () => {
    const debugging = sharedState.debugging.use();
    const [category, setCategory] = useState('');
    const [countsN, setCountsN] = useState(0);

    const categories = useMemo(() => {
        const o = Object.keys(debugging!).sort();
        if (!category && o.length > 0) {
            setCategory(o[0]);
        }
        return o;
    }, [debugging, category]);

    return (
        <div style={{ padding: '8px' }}>
            <SeaTabsGroup selectedTab={category} setTab={setCategory} mode="forms" mini>
                <SeaTab tab="Counts" mode="forms">Counts</SeaTab>
                {categories?.map((cat) => {
                    return <SeaTab key={cat} tab={cat} mode="forms">{cat}</SeaTab>
                })}
            </SeaTabsGroup>
            <div style={{ position: 'absolute', right: '8px' }}>
                <SeaButton
                    mini
                    onClick={(e) => {
                        sharedState.debugging.set((current) => {
                            const o = { ...current! };
                            if (category !== 'Counts') {
                                o[category] = ['',''];
                            }
                            return o;
                        });
                        Object.keys(debugCounts).forEach((id) => {
                            debugCounts[id] = 0;
                        });
                    }}
                >
                    Clear
                </SeaButton>
            </div>
            {category && category === 'Counts' &&
                <div style={{ padding: '4px' }}>
                    <table>
                        <tbody>
                            {Object.keys(debugCounts).map((id) => {
                                return (
                                    <tr key={id}>
                                        <td>{id}</td>
                                        <td style={{ paddingLeft: '8px', textAlign: 'right' }}>{debugCounts[id]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <br/>
                    <SeaButton onClick={(e) => { setCountsN((n) => n + 1) }} mini>Refresh ({countsN})</SeaButton>
                </div>
            }
            {category && category !== 'Counts' &&
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {debugging && renderDebugging(category)}
                </pre>
            }
        </div>
    );
};

export default DebuggingTab;
