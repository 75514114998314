import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { firestore, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, increment, serverTimestamp } from "firebase/firestore";
import { toMillis, preventMultiTap } from '../../../../lib/util';
import { formatDatetime } from '../../../../lib/datesAndTime';
import { makeFormElements } from '../../../../lib/customForms';
import { IonSelectOption } from '@ionic/react';
import { logAction } from '../../../../shared-state/General/actionLog';
import { renderFullName, renderFullNameForUserId } from '../../../../shared-state/Core/users';
import { sharedState } from '../../../../shared-state/shared-state';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { CustomForm } from '../../../../shared-state/CompanyDocuments/CustomForms/customForms';
import { UserType } from '../../../../shared-state/Core/user';
import { CustomFormCompleted } from '../../../../shared-state/CompanyDocuments/CustomForms/useCustomFormsCompleted';
import { renderVesselName } from '../../../../shared-state/Core/vessels';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { saveFileRefs, SeaFile, seaFilesToValue, signatureToValue } from '../../../../lib/files';
import { CustomFormVersion } from '../../../../shared-state/CompanyDocuments/CustomForms/customFormVersions';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import CustomFormElement from '../../../../components/CustomFormElement/CustomFormElement';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaFormHasErrors from '../../../../components/SeaFormHasErrors/SeaFormHasErrors';
import './CompleteCustomForm.css';

export interface OptionType {
    id: string;
    name: string;
}

interface CompleteCustomFormProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    setShowBrowseCustomForms?: (showModal: boolean) => void,
    level: number,
    customForm?: CustomForm,
    itemToUpdate?: any,
    forceVesselId?: string,
    forcePersonnelId?: string,
    attachTo?: null | 'voyage',
    attachToId?: string,
    attachToVesselId?: string
}

const CompleteCustomForm: React.FC<CompleteCustomFormProps> = ({
    showModal,
    setShowModal,
    setShowBrowseCustomForms,
    itemToUpdate,
    customForm,
    forceVesselId,
    forcePersonnelId,
    attachTo,
    attachToId,
    attachToVesselId,
    level
}) => {
    const licenseeId = sharedState.licenseeId.use(showModal);
    const users = sharedState.users.use(showModal);
    const userId = sharedState.userId.use(showModal)!;
    const vesselIds = sharedState.vesselIds.use(showModal);
    const customFormVersions = sharedState.customFormVersions.use(showModal);
    const [selectedVersion, setSelectedVersion] = useState<CustomFormVersion>();
    const [formElements, setFormElements] = useState<any[]>([]);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [selectedVesselIds, setSelectedVesselIds] = useState<string[]>();
    const [selectedCrewIds, setSelectedCrewIds] = useState<string[]>();
    const formRef = useRef<HTMLFormElement>(null);
    const [saveAsDraft, setSaveAsDraft] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onOpened = () => {
        if (!customFormVersions) {
            console.log('No customFormVersions');
            return;
        }
        setSaveAsDraft(false);
        setIsSubmitting(false);
        setHasSubmitted(false);
        let _selectedVersion;
        if (!customForm) { 
            console.log('No customForm');
            return;
        } else if (itemToUpdate) {
            _selectedVersion = customFormVersions.byFormIdAndVersion[customForm.id][itemToUpdate.version];
        } else {
            _selectedVersion = customFormVersions.byFormIdAndVersion[customForm.id][customForm.latestVersion];
        }
        setSelectedVersion(_selectedVersion);

        setFormElements(
            makeFormElements(_selectedVersion, itemToUpdate)
        );

        if (customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none') {
            if (itemToUpdate) {
                if (
                    itemToUpdate.vesselIds &&
                    itemToUpdate.vesselIds.length === 1 &&
                    itemToUpdate.vesselIds[0] === 'none'
                ) {
                    setSelectedVesselIds([]);
                } else {
                    setSelectedVesselIds(itemToUpdate.vesselIds);
                }
            } else if (forceVesselId) {
                setSelectedVesselIds([forceVesselId]);
            } else if (customForm.forVesselIds.length === 1) {
                setSelectedVesselIds(customForm.forVesselIds);
            } else {
                setSelectedVesselIds([]);
            }
        } else {
            setSelectedVesselIds([]);
        }

        if (customForm?.forCrew) {
            if (itemToUpdate) {
                setSelectedCrewIds(itemToUpdate.personnelIds);
            } else if (forcePersonnelId) {
                setSelectedCrewIds([forcePersonnelId]);
            } else {
                setSelectedCrewIds([]);
            }
        } else {
            setSelectedCrewIds([]);
        }
    };

    const vesselOptions = useMemo(() => {
        if (!(vesselIds && customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none')) {
            return [];
        }

        const result: OptionType[] = customForm.forVesselIds
            .filter(vesselId => vesselIds?.includes(vesselId))
            .map(id => ({id, name: renderVesselName(id)}))
            .sort((a, b) => a.name.localeCompare(b.name))

        return result
    }, [vesselIds, customForm]);

    const crewOptions = useMemo(() => {
        if (
            users?.staff &&
            customForm?.forCrew
        ) {
            const options: OptionType[] = [];
            users.staff.forEach((user: UserType) => {
                if (user.id) {
                    options.push({
                        id: user.id,
                        name: renderFullName(user)
                    });
                }
            });
            if (forcePersonnelId) {
                let found = false;
                for (let i = 0; i < options.length; i++) {
                    if (options[i].id === forcePersonnelId) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    options.push({
                        id: forcePersonnelId,
                        name: renderFullNameForUserId(forcePersonnelId)
                    });
                }
            }
            return options;
        }
        return [];
    }, [users, customForm, forcePersonnelId]);

    const updateElement = (element: any) => {
        setFormElements((_elements: any) => {
            const elements = [..._elements];
            for (let i = 0; i < _elements.length; i++) {
                if (elements[i].n === element.n) {
                    elements[i] = element;
                    return elements;
                }
            }
            return elements;
        });
    };

    const isValid = useMemo(() => {
        if (saveAsDraft) {
            return true;
        }

        const forVessels = (customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none');
        const forCrew = customForm?.forCrew;
        if (
            forVessels && (
                selectedVesselIds === undefined ||
                selectedVesselIds.length < 1 ||
                selectedVesselIds[0] === 'none'
            )
        ) {
            return false;
        }
        if (
            forCrew && (
                selectedCrewIds === undefined ||
                selectedCrewIds.length < 1
            )
        ) {
            return false;
        }
        if (!saveAsDraft) {
            for (let i = 0; i < formElements.length; i++) {
                if (formElements[i].error) {
                    return false;
                }
            }
        }
        return true;
    }, [customForm?.forCrew, customForm?.forVesselIds, formElements, selectedCrewIds, selectedVesselIds, saveAsDraft])


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setIsSubmitting(true);
        setHasSubmitted(true);
        if (preventMultiTap('completeCustomForm') || !isValid) { return; }
        console.log('CompleteCustomForm onSubmit! formElements', formElements);

        if (!saveAsDraft) {
            for (let i = 0; i < formElements.length; i++) {
                if (formElements[i].error) {
                    return;
                }
            }
        }
        const files = [] as SeaFile[];
        const data = {} as {
            [key: string]: any
        };
        formElements.forEach((element) => {
            if (element.value !== undefined) {
                switch (element.id) {
                    case 'date':
                    case 'datetime':
                        data[`e${element.n}`] = toMillis(element.value);
                        break;
                    case 'files':
                        element.value.forEach((file: SeaFile) => {
                            files.push(file);
                        });
                        break;
                    case 'signature':
                        if (element.value) {
                            files.push(element.value);
                            data[`e${element.n}`] = element.value;
                        } else {
                            data[`e${element.n}`] = undefined;
                        }
                        break;
                    default:
                        data[`e${element.n}`] = element.value;
                }
            }
        });

        uploadFiles(files).then(() => {
            const batch = splittableBatch(firestore, 20 - 1);
            const batchTrace = makeBatchTrace(batch, 'customFormsCompleted');

            if (!customForm || !selectedVersion) {
                console.error('No customForm or selectedVersion');
                return;
            }

            // Convert any processed file elements
            formElements.forEach((element) => {
                if (element.id === 'files') {
                    data[`e${element.n}`] = seaFilesToValue(element.value);
                } else if (element.id === 'signature') {
                    data[`e${element.n}`] = signatureToValue(element.value);
                }
            });

            let actionDetail = `${customForm?.title}${saveAsDraft ? ' (DRAFT)' : ''}`;
            if (selectedCrewIds && selectedCrewIds.length > 0) {
                actionDetail += ' - ';
                for (let i = 0; i < selectedCrewIds.length; i++) {
                    if (i > 0) {
                        actionDetail += ', ';
                    }
                    actionDetail += renderFullNameForUserId(selectedCrewIds[i]);
                }
            }

            console.log('Submitting data...', data);
            if (itemToUpdate) {
                // Update existing completed custom form
                batchTrace.exampleOperation = 'update';
                batchTrace.exampleDocId = itemToUpdate.id;
                const completedForm: any = {
                    data: data,
                    whenUpdated: batchTrace.whenAction,
                    updatedBy: userId,
                    vesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : ['none'],
                    personnelIds: (selectedCrewIds && selectedCrewIds.length > 0) ? selectedCrewIds : undefined,
                    isDraft: saveAsDraft,
                    touched: serverTimestamp()
                };

                if (!saveAsDraft && itemToUpdate.isDraft) {
                    // Submitting a draft, therefore update whenAdded and addedBy
                    completedForm.whenAdded = batchTrace.whenAction;
                    completedForm.addedBy = userId;
                }

                batch.set(
                    doc(firestore, 'customFormsCompleted', itemToUpdate.id),
                    completedForm as CustomFormCompleted,
                    { merge: true }
                );

                if (!saveAsDraft && itemToUpdate.isDraft) {
                    // Submitting a draft
                    // Therefore, need to update when last whenLastCompleted and lastCompletedBy for customForm
                    batch.set(
                        doc(firestore, 'customForms', customForm.id),
                        {
                            whenLastCompleted: batchTrace.whenAction,
                            lastCompletedBy: userId,
                            lastCompletedVesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : [],
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'customForms');
                }

                saveFileRefs(batch, files, 'customFormsCompleted', itemToUpdate.id);
                logAction(
                    batch,
                    'Update',
                    'customFormsCompleted',
                    itemToUpdate.id,
                    actionDetail,
                    (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : undefined,
                    (selectedCrewIds && selectedCrewIds.length > 0) ? selectedCrewIds : undefined
                );

                const originalFiles = [] as string[];
                formElements.forEach((element) => {
                    if (element.id === 'signature') {
                        originalFiles.push(
                            signatureToValue(element.initialValue)!
                        );
                    } else if (element.id === 'files') {
                        originalFiles.push(...seaFilesToValue(element.initialValue));
                    }
                });
            } else {
                // Create new completed custom form
                const customFormsCompletedRef = doc(collection(firestore, 'customFormsCompleted'));
                batchTrace.exampleOperation = 'create';
                batchTrace.exampleDocId = customFormsCompletedRef.id;
                batch.set(customFormsCompletedRef, {
                    licenseeId: licenseeId,
                    customFormId: customForm.id,
                    version: selectedVersion.version,
                    whenAdded: batchTrace.whenAction,
                    addedBy: userId,
                    vesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : ['none'],
                    personnelIds: (selectedCrewIds && selectedCrewIds.length > 0) ? selectedCrewIds : undefined,
                    data: data,
                    state: 'active',
                    isDraft: saveAsDraft,
                    attachTo: attachTo ? attachTo : null,
                    attachToId: attachToId ? attachToId : undefined,
                    attachToVesselId: attachToVesselId ? attachToVesselId : undefined,
                    touched: serverTimestamp()
                });

                batch.set(
                    doc(firestore, 'customFormVersions', selectedVersion.id),
                    { 
                        numCompleted: increment(1),
                        touched: serverTimestamp()
                    },
                    { merge: true }
                );

                logAction(
                    batch,
                    'Add',
                    'customFormsCompleted',
                    customFormsCompletedRef.id,
                    actionDetail,
                    (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : undefined,
                    (selectedCrewIds && selectedCrewIds.length > 0) ? selectedCrewIds : undefined
                );

                if (!saveAsDraft) {
                    batch.set(
                        doc(firestore, 'customForms', customForm.id),
                        {
                            whenLastCompleted: batchTrace.whenAction,
                            lastCompletedBy: userId,
                            lastCompletedVesselIds: (selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds : [],
                            touched: serverTimestamp()
                        },
                        { merge: true }
                    );
                    onCollectionUpdated(batch, 'customForms');
                }

                saveFileRefs(batch, files, 'customFormsCompleted', customFormsCompletedRef.id);
            }

            onCollectionUpdated(batch, 'customFormsCompleted');
            onCollectionUpdated(batch, 'customFormVersions'); // (alwaysActive so doesn't need dataSync)

            batchTrace.data = {
                data,
                selectedCrewIds,
                selectedVesselIds,
                saveAsDraft,
                attachTo,
                attachToId,
                attachToVesselId,
                itemToUpdate
            }
            batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} completed custom form ${customForm?.title}`);
            batch.commit().then(() => {
                batchTrace.reportSuccess();
            }).catch((error) => {
                batchTrace.reportError(error.message, error);
            });

            setShowModal(false);
            if (setShowBrowseCustomForms) {
                setShowBrowseCustomForms(false);
            }

        }).catch((error: any) => {
            if (!handleUploadError(error)) {
                reportError(`Failed to upload file${(files.length === 1)?'':'s'}`, error.message, error, {
                    data,
                    selectedVesselIds,
                    selectedCrewIds
                });
            }
        });
        setIsSubmitting(false);
    };

    const isModalDirty = useCallback(() => {
        for (let i = 0; i < formElements.length; i++) {
            if (formElements[i].isDirty) {
                return true;
            }
        }
        return false;
    }, [formElements]);

    const alignSelf = (id: string) => {
        return 'normal';
    }

    useEffect(() => {
        if (isSubmitting) {
            setHasSubmitted(true);
        }
    }, [isSubmitting]);

    return (
        <SeaModal
            title={`${customForm?.title}${(itemToUpdate?.isDraft) ? ' (Draft)' : ''}`}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            size="semi-wide"
            level={level}
            noContentPadding={true}
        >
            <form ref={formRef} onSubmit={onSubmit}>
                <div className="custom-form-container complete-form">
                    {itemToUpdate && customForm && selectedVersion && selectedVersion.version !== customForm.latestVersion &&
                        <div style={{
                            padding: '6px 6px 12px 6px',
                            color: 'var(--ion-color-danger)',
                            fontWeight: 'bold',
                            width: '100%'
                        }}>
                            <i>Note: This is an older version of the form dated {formatDatetime(selectedVersion.version)}.</i>
                        </div>
                    }
                    {customForm?.forVesselIds && customForm.forVesselIds[0] !== 'none' &&
                        <div
                            className={`w${(customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100}`}
                            style={{ width: `${(customForm?.vesselsElement?.width) ? Math.floor(customForm.vesselsElement.width) : 100}%` }}
                        >
                            <div className="element">
                                {(
                                    customForm === undefined ||
                                    customForm.vesselsElement === undefined ||
                                    customForm.vesselsElement.allowMultiple === undefined ||
                                    customForm.vesselsElement.allowMultiple
                                ) ? (
                                    <SeaMultiSelect
                                        mode="popover"
                                        label={(customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES'}
                                        values={selectedVesselIds}
                                        setValues={setSelectedVesselIds}
                                        options={vesselOptions}
                                        useAllOption={vesselOptions && vesselOptions.length > 1}
                                        required={true}
                                        isSubmitting={hasSubmitted}
                                        disabled={forceVesselId ? true : false}
                                        emptyText="Not Set"
                                    />
                                ) : (
                                    <SeaSelect
                                        label={(customForm?.vesselsElement?.label) ? customForm.vesselsElement.label : 'SELECT VESSELS / FACILITIES'}
                                        value={(selectedVesselIds && selectedVesselIds.length > 0) ? selectedVesselIds[0] : ''}
                                        onchange={(e) => {
                                            setSelectedVesselIds([e.detail.value]);
                                        }}
                                        error={hasSubmitted && selectedVesselIds && (selectedVesselIds.length === 0) ? 'You must choose a vessel' : ''}
                                        disabled={forceVesselId ? true : false}
                                    >
                                        {vesselOptions?.map((option) => {
                                            return (
                                                <IonSelectOption key={option.id} value={option.id}>
                                                    {option.name}
                                                </IonSelectOption>
                                            );
                                        })}
                                    </SeaSelect>
                                )}
                            </div>
                        </div>
                    }
                    {customForm?.forCrew &&
                        <div
                            className={`w${(customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100}`}
                            style={{ width: `${(customForm?.crewElement?.width) ? Math.floor(customForm.crewElement.width) : 100}%` }}
                        >
                            <div className="element">
                                {(
                                    customForm === undefined ||
                                    customForm.crewElement === undefined ||
                                    customForm.crewElement.allowMultiple === undefined ||
                                    customForm.crewElement.allowMultiple
                                ) ? (
                                    <SeaMultiSelect
                                        mode="popover"
                                        label={(customForm?.crewElement?.label) ? customForm.crewElement.label : 'SELECT PERSONNEL'}
                                        values={selectedCrewIds}
                                        setValues={setSelectedCrewIds}
                                        options={crewOptions}
                                        useAllOption={crewOptions && crewOptions.length > 1}
                                        required={true}
                                        isSubmitting={hasSubmitted}
                                        disabled={forcePersonnelId ? true : false}
                                        emptyText="Not Set"
                                    />
                                ) : (
                                    <SeaSelect
                                        label={(customForm?.crewElement?.label) ? customForm.crewElement.label : 'SELECT PERSONNEL'}
                                        value={(selectedCrewIds && selectedCrewIds.length > 0) ? selectedCrewIds[0] : ''}
                                        onchange={(e) => {
                                            setSelectedCrewIds([e.detail.value]);
                                        }}
                                        error={hasSubmitted && selectedCrewIds && (selectedCrewIds.length === 0) ? 'You must choose a crew member' : ''}
                                        disabled={forcePersonnelId ? true : false}
                                    >
                                        {crewOptions?.map((option) => {
                                            return (
                                                <IonSelectOption key={option.id} value={option.id}>
                                                    {option.name}
                                                </IonSelectOption>
                                            );
                                        })}
                                    </SeaSelect>
                                )}
                            </div>
                        </div>
                    }
                    {formElements.map((element) => {
                        return (
                            <div
                                key={element.n}
                                style={{ width: `${element.width}%`, alignSelf: alignSelf(element.id) }}
                                className={`w${Math.floor(element.width)} ${(element.width === '50' && element.id === 'signature') ? 'signature-cell' : ''}`}
                            >
                                <CustomFormElement
                                    element={element}
                                    setElement={(element: any) => updateElement(element)}
                                    mode="complete"
                                    selectedElement={undefined}
                                    onSelectElement={(element) => {
                                        // do nothing
                                    }}
                                    hasSubmitted={hasSubmitted}
                                />
                            </div>
                        );
                    })}
                    <div className='grid-row-spacer' style={{width: '100%'}}></div>
                    <SeaFormHasErrors
                        hasSubmitted={hasSubmitted}
                        isValid={isValid}
                    />
                    <div className="view-modal-buttons">
                        <SeaButton
                            size="wide"
                            onClick={(e) => {
                                console.log('normal submit!');
                                setSaveAsDraft(false);
                                setTimeout(() => {
                                    formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));  
                                }, 1);
                            }}
                        >
                            {(itemToUpdate && !itemToUpdate.isDraft) ? 'Update Completed Form' : 'Submit Form'}
                        </SeaButton>
                        <div className="spacer"></div>
                        {(itemToUpdate === undefined || itemToUpdate.isDraft) &&
                            <SeaButton
                                size="wide"
                                onClick={(e) => {
                                    console.log('save as draft!');
                                    setSaveAsDraft(true);
                                    setTimeout(() => {
                                        formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));  
                                    }, 1);
                                }}
                            >
                                {(itemToUpdate && itemToUpdate.isDraft) ? 'Update Draft' : 'Save As Draft'}
                            </SeaButton>
                        }
                    </div>
                </div>

            </form>
        </SeaModal>
    );
};

export default CompleteCustomForm;
