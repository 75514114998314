import React from 'react';
import { useEffect, useState, useRef, useCallback, useMemo } from "react";

export const usePageLimiter = (itemsPerPage = 25) => {
    const isMounted = useRef(false);
    const [limit, setLimit] = useState(itemsPerPage);
    const limitRef = useRef(itemsPerPage);
    const lastElementObserver = useRef({} as any);
    const counterRef = useRef(0);
    const isLastElementVisibleRef = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
            // Unmount observer
            if (lastElementObserver.current?.disconnect) {
                lastElementObserver.current.disconnect();
            }
        };
    }, []);

    const increaseLimit = useCallback(() => {
        setLimit((current) => {
            limitRef.current = current + itemsPerPage;
            return current + itemsPerPage;
        });
    }, [itemsPerPage]);

    // Checks to see if the current limit is OK.
    // If not then increase the limit.
    const checkLimit = useCallback(() => {
        if (counterRef.current >= limitRef.current && isLastElementVisibleRef.current) {
            setTimeout(() => {
                if (!isMounted.current) return;
                if (counterRef.current >= limitRef.current && isLastElementVisibleRef.current) {
                    increaseLimit();
                } else {
                    setTimeout(() => { // Another timeout to be extra careful
                        if (!isMounted.current) return;
                        if (counterRef.current >= limitRef.current && isLastElementVisibleRef.current) {
                            increaseLimit();
                        }
                    }, 1000);
                }
            }, 50);
        }
    }, [increaseLimit]);

    const resetPageLimit = useCallback(() => {
        setLimit(itemsPerPage);
        limitRef.current = itemsPerPage;
        checkLimit();
    }, [itemsPerPage, checkLimit]);

    useEffect(() => {
        // Runs after all renders
        checkLimit();
    });

    // Place this element at the end of ALL your rendered lists
    const triggerElementRef = useCallback((node: HTMLElement | null) => {
        if (lastElementObserver.current?.disconnect) {
            lastElementObserver.current.disconnect();
        }

        if (node) {
            lastElementObserver.current = new IntersectionObserver((entries) => {
                isLastElementVisibleRef.current = entries[0].isIntersecting;

                if (isLastElementVisibleRef.current && counterRef.current >= limitRef.current) {
                    increaseLimit();
                }
            });

            lastElementObserver.current.observe(node);
        }
    }, [increaseLimit]);

    const limitTriggerElement = useMemo(() => {
        return React.createElement(
            'div',
            {
                ref: triggerElementRef,
                style: {
                    height: '8px'
                }
            },
            ''
        );
    }, [triggerElementRef]);

    //const itemCounter = makeCounter();
    counterRef.current = 0;
    const isLimitReached = useCallback(() => {
        return counterRef.current >= limit;
    }, [limit]);

    const mapArrayWithLimit = useCallback((array: any[] | undefined, func: (item: any, index: number) => JSX.Element | undefined) => {
        if (array && array.length && !isLimitReached()) {
            const output = [] as JSX.Element[];
            for (let i = 0; i < array.length; i++) {
                if (isLimitReached()) {
                    break;
                }
                const element = func(array[i], i);
                if (element !== undefined) {
                    output.push(element);
                    counterRef.current++;
                }
            }
            return output;
        }
        return null;
    }, [isLimitReached]);

    return {
        limitTriggerElement,
        mapArrayWithLimit,
        resetPageLimit,
        isLimitReached
    };
}
