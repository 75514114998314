import {collection, onSnapshot, query, QueryDocumentSnapshot, where} from "firebase/firestore";
import {firestore} from "../../lib/firebase";
import {
    ArchivableDocument,
    CreateableDocument,
    sharedState,
    SharedStateConfig,
    UpdateableDocument
} from "../shared-state";
import {canView} from "../../shared-state/Core/userPermissions";
import {getDayOffsetMillis, warnDays} from '../../lib/datesAndTime';
import {renderFullNameForUserId, UsersData} from "../Core/users";
import {registerFiles} from "../FileSyncSystem/filesToCache";
import {CategoriesData, renderCategoryName} from '../../lib/categories';

//
// Loads crew certificates (active)
//

export interface CrewCertificate extends CreateableDocument, UpdateableDocument, ArchivableDocument {
    emailReminder?: string;
    files: string[];
    heldBy: string;
    issuedBy?: string;
    licenseeId: string;
    state: string;
    searchText?: string;
    title: string;
    titleId: string;
    type: string;
    wasRenewed?: boolean;
    whenExpires?: number;
    whenIssued: number;
    whenToRemind?: number;
}

export type CertificateHolderInfo = {
    id: string;
    name: string,
    roleIds: string[],
    certificates: CrewCertificate[]
};

export type CrewCertificatesData = {
    prioritised: CrewCertificate[],
    top5: CrewCertificate[],
    byId: {
        [id: string]: CrewCertificate
    },
    holdersSorted: CertificateHolderInfo[],
    holders: {
        [holderId: string]: CertificateHolderInfo
    },
    numHighestPriority: number,
    count: number
};

function mapCertificates(docs: QueryDocumentSnapshot[], crewCertificateTitles: CategoriesData) {
    return docs.map((doc) => {
        const item = {
            id: doc.id,
            ...doc.data(),
            title: renderCategoryName(doc.data().titleId, crewCertificateTitles),
        } as CrewCertificate;
        let searchText = item.title.toLowerCase();
        if (item.heldBy) {
            searchText += renderFullNameForUserId(item.heldBy).toLowerCase();
        }
        if (item.issuedBy) {
            searchText += item.issuedBy;
        }
        return {
            ...item,
            searchText
        }
    }).sort((a, b) => {
        return a.searchText.localeCompare(b.searchText);
    });
}

const registerFilesForCertificates = (certificates: CrewCertificate[]) => {
    certificates.forEach((certificate) => {
        registerFiles(certificate.files, 'crewCertificates', certificate)
    })
};

function getActiveCertificates(certificates: CrewCertificate[], users: UsersData) {
    return certificates.filter((cert: CrewCertificate) => users.byId[cert.heldBy] && users.byId[cert.heldBy].state === 'active');
}

const makeCompulsoryTitlesByRole = (crewCertificateTitles: CategoriesData) => {
    const result: { [roleId: string]: { id: string, name: string }[] } = {};

    for (const title of Object.values(crewCertificateTitles.byId)) {
        if (title.compulsoryForRoles?.length && title.state === 'active') {
            for (const roleId of title.compulsoryForRoles) {
                if (result[roleId] === undefined) {
                    result[roleId] = [];
                }
                result[roleId].push({id: title.id, name: title.name});
            }
        }
    }

    return result;
};

const makeTop5PrioritisedCertificates = (prioritisedCertificates: CrewCertificate[]) => {
    const minWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[0]);

    return prioritisedCertificates.slice(0, 5).filter((p) => p.whenExpires && p.whenExpires < minWhenExpires);
};

const makePrioritisedCertificates = (activeCertificates: CrewCertificate[]) => {
    let prioritised = [...activeCertificates] as CrewCertificate[];
    prioritised.sort((a, b) => {
        return ((a.type === 'renewable' ? a.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) -
            (b.type === 'renewable' ? b.whenExpires ?? Number.MAX_SAFE_INTEGER : Number.MAX_SAFE_INTEGER));
    });
    // prioritised should only contain whenExpires up to a set amount days in the future
    // (and should not contain any nonExpiring either)
    const maxWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[warnDays.crewCertificates.length - 1]);
    const minWhenExpires = getDayOffsetMillis(warnDays.crewCertificates[0]);
    let numHighestPriority = 0
    for (let i = 0; i < prioritised.length; i++) {
        if (prioritised[i].whenExpires && (prioritised[i].whenExpires as number) < minWhenExpires && prioritised[i].type !== 'nonExpiring') {
            numHighestPriority++
        }
        if (prioritised[i].type === 'nonExpiring' || (prioritised[i].whenExpires && prioritised[i].whenExpires as number >= maxWhenExpires)) {
            prioritised = prioritised.slice(0, i);
            break;
        }

    }

    return {prioritisedCertificates: prioritised, numHighestPriority};
};

const processUserCertificates = (users: UsersData, activeCertificates: CrewCertificate[], crewCertificateTitles: CategoriesData, licenseeId: string) => {
    const certificatesResult = [...activeCertificates];
    const holdersResult: { [id: string]: CertificateHolderInfo } = {}

    const compulsoryTitles: {
        [roleId: string]: { id: string; name: string }[]
    } = makeCompulsoryTitlesByRole(crewCertificateTitles)

    const activeUsers = users.all.filter((user) => user.state === 'active' && !!user.id)

    activeUsers.forEach(user => {
        holdersResult[user.id!] = {
            id: user.id!,
            name: renderFullNameForUserId(user.id),
            roleIds: user.roleIds ?? [],
            certificates: activeCertificates.filter((c: CrewCertificate) => c.heldBy === user.id),
        }

        const roleIds = user.roleIds ?? [];
        const userCompulsoryTitles = roleIds.map((roleId) => compulsoryTitles[roleId] ?? []).flat();

        userCompulsoryTitles.forEach((title) => {
            const existingCertificate = activeCertificates.find(cert => cert.heldBy === user.id && cert.titleId === title.id)

            if (!existingCertificate || !existingCertificate.files.length) {
                const missingCertificate: CrewCertificate = {
                    id: `missing-${user.id}-${title.id}`,
                    files: [],
                    heldBy: user.id!, // Will not be undefined as we have checked for it above
                    licenseeId: licenseeId,
                    state: 'missing',
                    title: title.name,
                    titleId: title.id,
                    type: 'compulsory',
                    whenIssued: Date.now(),
                    whenAdded: Date.now(),
                    addedBy: '',
                }

                if (!existingCertificate) { // Cert doesn't exist so add it
                    certificatesResult.push(missingCertificate)
                    holdersResult[user.id!].certificates.push(missingCertificate)
                } else { // Cert does exist but has no files
                    // TODO - Do we need to update the state to be 'missing' if the cert has no files?
                }
            }
        })
    })

    return {processedCertificates: certificatesResult, processedCertificateHolders: holdersResult};
};

function buildCertificatesById(processedCertificates: CrewCertificate[]) {
    return processedCertificates.reduce((result, cert) => {
        result[cert.id] = cert;
        return result;
    }, {} as { [id: string]: CrewCertificate });
}

const buildCertificateHoldersSorted = (certificateHolders: { [p: string]: CertificateHolderInfo }) => {
    // Sort certs array for holders
    for (const userId in certificateHolders) {
        certificateHolders[userId].certificates.sort((a, b) => a.title.localeCompare(b.title));
    }

    // Remove holders who do not have any certificates
    const holdersWithAtLeastOneCertificate = Object.fromEntries(
        Object.entries(certificateHolders)
            .filter(([userId, certificateHolderInfo]) => certificateHolderInfo.certificates.length > 0)
    );

    // Build sorted array of CertHolderInfo based on cert name
    return Object.values(holdersWithAtLeastOneCertificate).sort((a, b) => a.name.localeCompare(b.name))
};

/* Filters the Certificate Holders to remove those who do not have any certificates */
function makeHoldersWithCertificates(processedCertificateHolders: { [p: string]: CertificateHolderInfo }) {
    return Object.fromEntries(
        Object.entries(processedCertificateHolders)
            .filter(([userId, certificateHolderInfo]) => certificateHolderInfo.certificates.length > 0)
    );
}

const processDocs = (docs: QueryDocumentSnapshot[], userId: string, licenseeId: string, users: UsersData, crewCertificateTitles: CategoriesData): CrewCertificatesData => {
    const _certificates: CrewCertificate[] = mapCertificates(docs, crewCertificateTitles)

    registerFilesForCertificates(_certificates)

    const activeCertificates = getActiveCertificates(_certificates, users)

    const {prioritisedCertificates, numHighestPriority} = makePrioritisedCertificates(activeCertificates)
    const top5: CrewCertificate[] = makeTop5PrioritisedCertificates(prioritisedCertificates)

    const {
        processedCertificates,
        processedCertificateHolders
    } = processUserCertificates(users, activeCertificates, crewCertificateTitles, licenseeId)

    const byId = buildCertificatesById(processedCertificates)
    const holders = makeHoldersWithCertificates(processedCertificateHolders);
    const holdersSorted = buildCertificateHoldersSorted(holders)

    return {
        prioritised: prioritisedCertificates,
        numHighestPriority,
        top5,
        byId,
        holders,
        holdersSorted,
        count: _certificates.length
    }
};

export const crewCertificatesConfig: SharedStateConfig<CrewCertificatesData> = {
    isAlwaysActive: false,
    dependencies: ['userId', 'licenseeId', 'users', 'crewCertificateTitles'],
    countLiveDocs: () => sharedState.crewCertificates.current?.count ?? 0,
    run: (done, set, clear) => {
        clear();

        // Dependant data
        const userId = sharedState.userId.current;
        const licenseeId = sharedState.licenseeId.current;
        const users = sharedState.users.current;
        const crewCertificateTitles = sharedState.crewCertificateTitles.current;

        // Return early if we're missing dependent data
        if (!userId || !licenseeId || !users || !crewCertificateTitles) return;

        return onSnapshot(
            query(
                collection(firestore, 'crewCertificates'),
                canView('crewCertificates') ? (
                    where('licenseeId', '==', licenseeId)
                ) : (
                    where('heldBy', '==', userId)
                ),
                where('state', '==', 'active')
            ),
            (snap) => {
                done();
                set(
                    processDocs(snap.docs, userId, licenseeId, users, crewCertificateTitles)
                );
            }, (error) => {
                // This should be very rare
                done();
                console.error('Failed to access crew certificates ', error);
            }
        );
    }
};
