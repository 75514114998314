import { getBytes, ref as storageRef } from "firebase/storage";
import { storage } from './firebase';
import { toByteArray } from 'base64-js';
import { SeaFile } from "./files";
import { SFDoc } from '../shared-state/CompanyDocuments/companyDocuments';
import { cachedFiles, getCachedFileSrc, saveFileToLocalStorage } from "../shared-state/FileSyncSystem/cachedFiles";
import lzString from 'lz-string';
import { isPlatform } from "@ionic/react";
import { debugApp } from "../shared-state/Core/debugging";

export interface RichTextState {
    versions: any | undefined,
    loadedVersion: number | undefined,
    loadedJson: any | undefined,
    loading: boolean,
    downloading: boolean,
    message: string | undefined
};

export const initialRichTextState = {
    versions: undefined,
    loadedVersion: undefined,
    loadedJson: undefined,
    loading: false,
    downloading: false,
    message: undefined
} as RichTextState;

export const makeSfdocSeaFile = (
    base64: string,
    collection: string,
    name: string,
    authorId: string
) => {
    return {
        collection: collection,
        field: 'sfdoc',
        ext: 'sfdoc',
        contentType: 'application/seaflux',
        lastModified: Date.now(),
        base64: base64, // binary to ascii. use atob() to get original string back
        name: `${name}.sfdoc`,
        authorId: authorId
    } as SeaFile;
};

export const loadSfdoc = (
    sfdoc: SFDoc | undefined,
    setRichTextState: React.Dispatch<React.SetStateAction<RichTextState>>,
    getDefaultContent?: () => string,
    hasPrerequisites = true
) => {
    let isActive = true;
    debugApp('sfdoc', `Load SFDoc...`);

    if (hasPrerequisites && sfdoc && Object.keys(sfdoc).length > 0) {

        const versions = Object.keys(sfdoc).map((key) => {
            return parseInt(key);
        });
        versions.sort((a, b) => {
            return b - a;
        });
        const versionToView = versions[0];
        
        const fileState = parseInt(sfdoc[versionToView][0]);
        const fileId = sfdoc[versionToView].substring(1, 21);
        let isOptimised = (fileState === 2);

        const onSuccess = (content: string | undefined) => {
            if (!isActive) return;
            if (content === undefined) return;

            let json;
            if (isOptimised) {
                if (content.startsWith('{"root":')) {
                    // Not compressed using lz-string
                    json = JSON.parse(content);
                } else {
                    const st = Date.now();
                    const decompressed = lzString.decompressFromUTF16(content);
                    console.log(`Decompressed sfdoc (${(decompressed.length - content.length) * 100 / decompressed.length}% compression) in ${Date.now() - st}ms`)
                    if (!isActive) return;
                    json = JSON.parse(decompressed);
                }
            } else {
                json = JSON.parse(content);
            }

            if (!isActive) return;
            // Successfully loaded document
            console.log(`loadSfdoc Successfully loaded document ${sfdoc[versionToView]}`);
            debugApp('sfdoc', `Loaded SFDoc versionToView=${versionToView} json.length=${JSON.stringify(json).length}`);
            setRichTextState({
                versions: versions,
                loadedVersion: versionToView,
                loadedJson: json,
                loading: false,
                downloading: false,
                message: undefined
            });
        };

        const onFailed = (message: string, error?: any) => {
            console.log('Failed to get document', error);
            debugApp('sfdoc', `Failed to load SFDoc versionToView=${versionToView} error`, error);
            if (!isActive) return;
            setRichTextState({
                versions: undefined,
                loadedVersion: undefined,
                loadedJson: undefined,
                loading: false,
                downloading: false,
                message: message
            });
        };

        const downloadDocument = (): Promise<string | undefined> => {
            // Obtain document via download
            // Returns document JSON on success
            // Returns undefined if no longer active

            //  Update state
            setRichTextState((current) => {
                if (current.loadedVersion === versionToView) {
                    console.log('loadSfdoc downloadDocument already loaded version', versionToView);
                    debugApp('sfdoc', `loadSfdoc downloadDocument already loaded versionToView=${versionToView}`);
                    return current; // (Already loaded)
                }
                return {
                    versions: undefined,
                    loadedVersion: undefined,
                    loadedJson: undefined,
                    loading: true,
                    downloading: true,
                    message: undefined
                };
            });

            const fileRef = storageRef(storage, `files/${fileId}${fileState === 2 ? '_opt' : ''}.sfdoc`);

            return getBytes(fileRef).then((buffer: ArrayBuffer) => {
                console.log('loadSfdoc got document via download.');
                if (!isActive) return Promise.resolve();

                const decoder = new TextDecoder();
                const content = decoder.decode(buffer);

                if (!isPlatform('hybrid')) {
                    // Cache sfdoc for next time we view it (desktop only)
                    return saveFileToLocalStorage(
                        fileId,
                        'sfdoc',
                        'R',
                        new Blob([buffer], { type: 'application/seaflux' })
                    ).then(() => {
                        console.log(`[FileSync] Cached sfdoc on desktop ${fileId}`);
                        return Promise.resolve(content as any);
                    });
                }

                return Promise.resolve(content as any);
            });
        };

        const getCachedDocument = ():Promise<string | undefined> => {
            // Obtain document via download
            // Returns document JSON on success
            // Returns undefined if no longer active

            //  Update state
            setRichTextState((current) => {
                if (current.loadedVersion === versionToView) {
                    console.log('loadSfdoc getCachedDocument already loaded version', versionToView);
                    debugApp('sfdoc', `getCachedDocument already loaded version versionToView=${versionToView}`);
                    return current; // (Already loaded)
                }
                return {
                    versions: undefined,
                    loadedVersion: undefined,
                    loadedJson: undefined,
                    loading: true,
                    downloading: false,
                    message: undefined
                };
            });

            return getCachedFileSrc(
                sfdoc[versionToView],
                'R'
            ).then((value: string) => {
                // Got back cached file (base64 string)
                console.log('sfdoc got back cached file');
                const data = value.substring(value.indexOf(',')+2);
                const utf8Bytes = toByteArray(data);
    
                if (!isActive) return;
                const decoder = new TextDecoder();
                return Promise.resolve(
                    decoder.decode(utf8Bytes)
                );
            });
        };

        if (fileState === 0) {
            // The only chance we have this document is if it is cached
            getCachedDocument().then((content: string | undefined) => {
                onSuccess(content);
            }).catch((error) => {
                console.log('Failed to getCachedDocument (state=0)', error);
                onFailed(`The latest version hasn't been uploaded by the device it was created on yet. To resolve this, please keep Sea Flux open on the originating device while connected to the internet.`);
            });
        } else if (
            fileState === 2 &&
            cachedFiles[fileId] &&
            cachedFiles[fileId][5].R === undefined // We don't have optimised version cached
        ) {
            // Cache is old, therefore attempt download with getCachedDocument() as a fallback
            console.log('Our cache is old!');
            downloadDocument().catch((error) => {
                console.log('Failed to download new state=2 document', error);
                isOptimised = false;
                return getCachedDocument();
            }).then((content: string | undefined) => {
                onSuccess(content);
            }).catch((error) => {
                onFailed(`Sea Flux is currently unable to access the latest version.`, error);
            });
        } else {
            // Attempt to get cached document with downloadDocument() as a fallback
            getCachedDocument().catch((error) => {
                console.log('Failed to getCachedDocument', error);
                return downloadDocument();
            }).then((content: string | undefined) => {
                onSuccess(content);
            }).catch((error) => {
                onFailed(`Sea Flux is currently unable to access the latest version.`, error);
            });
        }

    } else {
        // No document to load
        debugApp('sfdoc', `No document to load.`);
        setRichTextState({
            versions: undefined,
            loadedVersion: undefined,
            loadedJson: getDefaultContent ? JSON.parse(getDefaultContent()) : undefined,
            loading: false,
            downloading: false,
            message: undefined
        });
    }

    return () => {
        isActive = false;
    };
};