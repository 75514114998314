import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../../lib/firebase";
import { SharedStateConfig, UpdateableDocument, sharedState } from "../shared-state";


export type RiskRegisterSettings = {
    version: 1 | 2,
    likelihoods: {
        rows: number[],
        [Ln: string]: {
            name: string,
            description: string
        } | number[],
    },
    consequences: {
        columns: number[],
        [Cn: string]: {
            name: string,
            description: string
        } | number[]
    },
    matrix: {
        [cell: string]: string
    },
    evaluations: {
        name: string,
        color: string,
        description: string
    }[],
    riskPrompts: string[],
    controlPrompts: string[]
};

export interface LicenseeSettings extends UpdateableDocument {
    hasMfaEmail: boolean;
    hasOffline: boolean;
    maxSessionSeconds: number;
    region: string;
    riskRegister: RiskRegisterSettings;
    hasReporting: boolean;
    hasIncidents: boolean;
    previousLicenseeIds?: string[]; // Holds userIds for users who were the licensee in the past
}
export const licenseeSettingsConfig: SharedStateConfig<LicenseeSettings> = {
    isAlwaysActive: true,
    dependencies: ['licenseeId'],
    countLiveDocs: () => sharedState.licenseeSettings.current ? 1 : 0,
    run: (done, set, clear) => {
        clear();
        const licenseeId = sharedState.licenseeId.current;
        if (licenseeId) {
            return onSnapshot(
                doc(firestore, 'licenseeSettings', licenseeId),
                (doc) => {
                    done();
                    if (doc.exists()) {
                        set({
                            ...doc.data()
                        } as LicenseeSettings);
                    }
                }, (error) => {
                    done();
                    console.log(`Error getting licenseeSettings for licensee ${licenseeId}`, error);
                }
            );
        }
    },
};
