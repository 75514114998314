import React, { useState, useMemo, useCallback } from 'react';
import { IonGrid, IonRow, IonCol, IonSelectOption } from '@ionic/react';
import { useFormik } from 'formik';
import { firestore, deleteValue, splittableBatch } from '../../../../lib/firebase';
import { collection, doc, serverTimestamp } from "firebase/firestore";
import { haveValuesChanged, preventMultiTap } from '../../../../lib/util';
import { logAction } from '../../../../shared-state/General/actionLog';
import { sharedState } from '../../../../shared-state/shared-state';
import { sendVesselNotification } from '../../../../shared-state/Core/vessel';
import { onCollectionUpdated } from '../../../../shared-state/DataSyncSystem/dataSync';
import { reportError, makeBatchTrace } from '../../../../managers/ErrorsManager/ErrorsManager';
import { handleUploadError, uploadFiles } from '../../../../managers/FileUploadManager/FileUploadManager';
import { haveFilesChanged, makeSeaFiles, saveFileRefs, SeaFile, seaFilesToValue } from '../../../../lib/files';
import { Hazard } from '../../../../shared-state/HealthSafety/hazardRegistry_deprecated';
import { VesselOptions } from '../../../../shared-state/Core/vessels';
import SeaModal from '../../../../components/SeaModal/SeaModal';
import Yup from '../../../../lib/yup'
import SeaInput from '../../../../components/SeaInput/SeaInput';
import SeaButton from '../../../../components/SeaButton/SeaButton';
import SeaFileUpload from '../../../../components/SeaFileUpload/SeaFileUpload';
import SeaSelect from '../../../../components/SeaSelect/SeaSelect';
import SeaTextarea from '../../../../components/SeaTextarea/SeaTextarea';
import SeaCheckbox from '../../../../components/SeaCheckbox/SeaCheckbox';
import SeaMultiSelect from '../../../../components/SeaMultiSelect/SeaMultiSelect';


interface EditHazardProps {
    showModal: boolean,
    setShowModal: (showModal: boolean) => void,
    level?: number,
    defaultVesselId?: string,
    itemToUpdate?: Hazard,
}

const EditHazard: React.FC<EditHazardProps> = ({showModal, setShowModal, defaultVesselId, itemToUpdate, level}) => {
    const userId = sharedState.userId.use(showModal);
    const user = sharedState.user.use(showModal);
    const vessels = sharedState.vessels.use(showModal);
    const [vesselIds, setVesselIds] = useState<string[]>();
    const [vesselOptions, setVesselOptions] = useState<Partial<VesselOptions>[]>();
    const [files, setFiles] = useState<SeaFile[]>([]);

    const initialValues = useMemo(() => {
        if (itemToUpdate) {
            return {
                vesselIds: itemToUpdate.vesselIds,
                name: itemToUpdate.name ? ''+itemToUpdate.name : '',
                action: itemToUpdate.action ? ''+itemToUpdate.action : '',
                whoResponsible: itemToUpdate.whoResponsible ? ''+itemToUpdate.whoResponsible : '',
                riskRating: itemToUpdate.riskRating ? ''+itemToUpdate.riskRating : '',
                riskNotes: itemToUpdate.riskNotes ? ''+itemToUpdate.riskNotes : '',
                actionsRequired: itemToUpdate.actionsRequired ? ''+itemToUpdate.actionsRequired : '',
                shouldReportToManagement: itemToUpdate.shouldReportToManagement ? true : false,
            };
        } else {
            return {
                vesselIds: defaultVesselId ? [defaultVesselId] : [],
                name: '',
                action: '',
                whoResponsible: '',
                riskRating: '',
                riskNotes: '',
                actionsRequired: '',
                shouldReportToManagement: false,
            };
        }
    }, [itemToUpdate, defaultVesselId]);

    const onOpened = () => {
        resetForm();
        setValues(initialValues);

        const vesselIds = itemToUpdate ? itemToUpdate.vesselIds : (defaultVesselId ? [defaultVesselId] : []);
        const vesselOptions = user?.vesselIds?.map((vesselId: string) => ({
                id: vesselId,
                name: vessels?.byId[vesselId]?.name
            }
        )).sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0);

        setVesselIds(vesselIds);
        setVesselOptions(vesselOptions);
        setFiles(makeSeaFiles(itemToUpdate?.files));
    }

    const {handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue, setValues, resetForm, isSubmitting } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            name: Yup.string().max(500).required(),
            action: Yup.string().max(500),
            whoResponsible: Yup.string().max(500),
            riskRating: Yup.string().max(200).required(),
            riskNotes: Yup.string().max(5000),
            actionsRequired: Yup.string().max(5000),
            shouldReportToManagement: Yup.boolean(),
        }), onSubmit: (data) => {
            // If vessels array is empty - update error state
            if (preventMultiTap('hazard') || vesselIds === undefined || vesselIds.length === 0) {
                return;
            };
            // Attempt upload first.... ?
            uploadFiles(files).then(() => {
                // Process form
                const batch = splittableBatch(firestore, 20 - 0);
                const batchTrace = makeBatchTrace(batch, 'hazards');

                if (itemToUpdate) {
                    batchTrace.exampleOperation = 'update';
                    batchTrace.exampleDocId = itemToUpdate.id;
                    batch.set(
                        doc(firestore, 'hazards', itemToUpdate.id),
                        {
                            updatedBy: userId,
                            whenUpdated: batchTrace.whenAction,
                            name: data.name,
                            vesselIds: vesselIds,
                            action: data.action ? data.action : deleteValue,
                            whoResponsible: data.whoResponsible ? data.whoResponsible : deleteValue,
                            riskRating: data.riskRating,
                            riskNotes: data.riskNotes ? data.riskNotes : deleteValue,
                            actionsRequired: data.actionsRequired ? data.actionsRequired : deleteValue,
                            shouldReportToManagement: data.shouldReportToManagement ? data.shouldReportToManagement : deleteValue,
                            files: seaFilesToValue(files),
                            touched: serverTimestamp(),
                        },
                        { merge: true }
                    );

                    saveFileRefs(batch, files, 'hazards', itemToUpdate.id);
                    logAction(
                        batch,
                        'Update',
                        'hazards',
                        itemToUpdate.id,
                        data.name,
                        itemToUpdate.vesselIds
                    );

                    if (data.shouldReportToManagement) {
                        sendVesselNotification(batch, 'hazardReported', 'hazards', {
                            isUpdate: true,
                            id: itemToUpdate.id,
                            name: data.name,
                            riskNotes: data.riskNotes ? data.riskNotes : undefined,
                            riskRating: data.riskRating,
                            action: data.action ? data.action : undefined,
                            actionsRequired: data.actionsRequired ? data.actionsRequired : undefined,
                            whoResponsible: data.whoResponsible ? data.whoResponsible : undefined,
                        }, files, itemToUpdate.vesselIds);
                    }
                } else {
                    const newRef = doc(collection(firestore, 'hazards'));
                    batchTrace.exampleOperation = 'create';
                    batchTrace.exampleDocId = newRef.id;
                    batch.set(newRef, {
                        vesselIds: vesselIds,
                        addedBy: userId,
                        whenAdded: batchTrace.whenAction,
                        name: data.name,
                        action: data.action ? data.action : undefined,
                        whoResponsible: data.whoResponsible ? data.whoResponsible : undefined,
                        riskRating: data.riskRating,
                        riskNotes: data.riskNotes ? data.riskNotes : undefined,
                        actionsRequired: data.actionsRequired ? data.actionsRequired : undefined,
                        shouldReportToManagement: data.shouldReportToManagement ? data.shouldReportToManagement : undefined,
                        state: 'active',
                        files: seaFilesToValue(files),
                        touched: serverTimestamp(),
                    });

                    saveFileRefs(batch, files, 'hazards', newRef.id);
                    logAction(
                        batch,
                        'Add',
                        'hazards',
                        newRef.id,
                        data.name,
                        vesselIds
                    );
                    if (data.shouldReportToManagement) {
                        sendVesselNotification(batch, 'hazardReported', 'hazards', {
                            id: newRef.id,
                            name: data.name,
                            riskNotes: data.riskNotes ? data.riskNotes : undefined,
                            riskRating: data.riskRating,
                            action: data.action ? data.action : undefined,
                            actionsRequired: data.actionsRequired ? data.actionsRequired : undefined,
                            whoResponsible: data.whoResponsible ? data.whoResponsible : undefined
                        }, files, vesselIds);
                    }
                }

                onCollectionUpdated(batch, 'hazards');

                vesselIds.forEach((vesselId: string) => {
                    onCollectionUpdated(batch, 'hazards', vesselId);
                });

                batchTrace.data = {
                    data,
                    files: seaFilesToValue(files),
                    itemToUpdate
                };
                batchTrace.save(`${itemToUpdate ? 'Update' : 'Add'} hazard ${data.name}`);
                batch.commit().then(() => {
                    batchTrace.reportSuccess();
                }).catch((error) => {
                    batchTrace.reportError(error.message, error);
                });

                setShowModal(false);
            }).catch((error: any) => {
                if (!handleUploadError(error)) {
                    reportError(`Failed to upload hazard files`, error.message, error, {
                        data,
                        itemToUpdate,
                        files: seaFilesToValue(files)
                    });
                }
            });
        }
    });
    
    const isModalDirty = useCallback(() => {
        return (
            haveValuesChanged(values, initialValues) ||
            haveFilesChanged(files, itemToUpdate?.files)
        );
    }, [initialValues, values, files, itemToUpdate?.files])

    return (
        <SeaModal
            title={itemToUpdate ? 'Edit Hazard' : 'Add New / Report Hazard'}
            showModal={showModal}
            setShowModal={setShowModal}
            isDirty={isModalDirty}
            onOpened={onOpened}
            level={level}
        >
            <form onSubmit={handleSubmit}>
                <IonGrid className="form-grid">
                    <IonRow>
                        <IonCol size="12">
                            <SeaMultiSelect
                                label="Vessels / Facilities"
                                help={{text: 'Select which vessels and/or facilities this hazard/risk is associated with.'}}
                                modalTitle="Vessels / Facilities"
                                values={vesselIds}
                                setValues={setVesselIds}
                                options={vesselOptions}
                                useAllOption={true}
                                required={true}
                                isSubmitting={isSubmitting}
                                emptyText="Not Set"
                            />
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Hazard"
								name="name"
								value={values.name}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.name ? errors.name : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Key Action"
								name="action"
								value={values.action}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.action ? errors.action : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                          	<SeaInput
								label="Person/s Responsible"
								name="whoResponsible"
								value={values.whoResponsible}
								onchange={handleChange}
								onblur={handleBlur}
								zone="white"
								type="text"
								inputmode="text"
								error={touched.whoResponsible ? errors.whoResponsible : ''}
                          	/>
                        </IonCol>
                        <IonCol size="6">
                            <SeaSelect
                                label="Risk Rating"
                                name="riskRating"
                                zone="white"
                                value={values.riskRating}
                                onchange={handleChange}
                                onblur={handleBlur}
                                error={touched.riskRating ? errors.riskRating : ''}
                            >
                                <IonSelectOption value="">Not Set</IonSelectOption>
                                <IonSelectOption value="6">High</IonSelectOption>
                                <IonSelectOption value="4">Medium</IonSelectOption>
                                <IonSelectOption value="2">Low</IonSelectOption>
                            </SeaSelect>
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Risk Notes"
                                name="riskNotes"
                                value={values.riskNotes}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.riskNotes ? errors.riskNotes : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaTextarea
                                label="Actions Required"
                                name="actionsRequired"
                                value={values.actionsRequired}
                                onchange={handleChange}
                                onblur={handleBlur}
                                zone="white"
                                inputmode="text"
                                error={touched.actionsRequired ? errors.actionsRequired : ''}
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaFileUpload
                                label="Images / Documents"
                                files={files}
                                setFiles={setFiles}
                                collection="hazards"
                                field="files"
                            />
                        </IonCol>
                        <IonCol size="12">
                            <SeaCheckbox
                                name="shouldReportToManagement"
                                checked={values.shouldReportToManagement}
                                setFieldValue={setFieldValue}
                                checkedColor="primary"
                                error={touched.shouldReportToManagement ? errors.shouldReportToManagement : ''}
                            >
                                Report to Management
                            </SeaCheckbox>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{ height: '60px' }}></div>
                <SeaButton zone="white" type="submit">{itemToUpdate ? 'Update Hazard' : 'Save New Hazard'}</SeaButton>
            </form>
        </SeaModal>
    );
};

export default EditHazard;
